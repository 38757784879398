import * as msal from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId: process.env.NEXT_PUBLIC_B2C_CLIENT_ID,
    authority: process.env.NEXT_PUBLIC_B2C_AUTHORITY,
    knownAuthorities: [`${process.env.NEXT_PUBLIC_B2C_KNOWN_AUTHORITY}`],
    redirectUri: process.env.NEXT_PUBLIC_B2C_REDIRECT_URI,
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCooke: false,
  },
};

export const loginRequest = {
  scopes: [`${process.env.NEXT_PUBLIC_B2C_TOKEN_REFRESH}`],
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

export { msalInstance };
